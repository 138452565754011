<script setup lang="ts">
    import { Swiper, SwiperSlide } from 'swiper/vue';
    import { Pagination } from 'swiper/modules';
    import 'swiper/css';
    import 'swiper/css/pagination';

    const { setMainSwiper, setControls, onSlideFocus } = useGallery();

    const md = screens('md');
    const lg = screens('lg');
</script>
<template>
    <div>
        <div class="org-paginated-swiper">
            <nuxt-error-boundary @error="() => {}">
                <swiper
                    :slides-per-view="1"
                    :lazy="true"
                    :breakpoints="{
                        [md]: {
                            slidesPerView: 2,
                        },
                        [lg]: {
                            slidesPerView: 4,
                        },
                    }"
                    :pagination="{
                        clickable: true,
                        dynamicBullets: false,
                    }"
                    :modules="[Pagination]"
                    :space-between="16"
                    @slide-change="setControls"
                    @swiper="setMainSwiper"
                    loop>
                    <swiper-slide
                        v-for="(_, index) in Array(4)"
                        class="w-full md:w-1/2 lg:w-1/4"
                        :key="index"
                        @focusin="() => onSlideFocus(index)">
                        <div class="flex h-full flex-col justify-end gap-6 overflow-hidden text-center">
                            <atm-image
                                :src="`/account/ridersclub-benefits-${index + 1}.png`"
                                sizes="100vw md:50vw lg:25vw"
                                role="presentation" />
                            <span
                                class="font-bold"
                                v-html="$t(`account.benefits[${index}]`)"></span>
                        </div>
                    </swiper-slide>
                </swiper>
            </nuxt-error-boundary>
        </div>
    </div>
</template>

<style scoped>
    :deep(.swiper-pagination-bullet-active) {
        @apply bg-woom-black;
    }
    :deep(.swiper-pagination) {
        @apply relative mt-5 lg:hidden;
    }
    :deep(.swiper-horizontal > .swiper-pagination-bullets),
    :deep(.swiper-pagination-bullets.swiper-pagination-horizontal) {
        @apply flex justify-center lg:hidden;
    }
    :deep(.swiper-pagination-bullet) {
        @apply mx-0 my-2;
    }
</style>
